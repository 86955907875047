import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { Button } from '@components/atoms/Button/Button';
import { Title } from '../../atoms/Title';

export interface AboElementProps extends PropsWithClassProps<'root'> {
  imageSrc?: string;
  imageAlt?: string;
  title: string;
  descriptionText: string;
  ctaText: string;
  link: string;
}

export const AboElement = ({
  classProps = { root: '' },
  imageSrc,
  imageAlt,
  title,
  descriptionText,
  ctaText,
  link,
}: AboElementProps) => {
  return (
    <div
      className={`relative mx-auto mb-24 mt-25 w-full overflow-hidden rounded-2xl bg-primary text-center ${classProps.root}`}
    >
      {imageSrc && (
        <div className="absolute inset-0 hidden size-full lg:block">
          <Image src={imageSrc} alt={imageAlt} fill className="size-full rounded-2xl" copyright="" />
        </div>
      )}

      <div
        className={
          'relative grid h-full content-center items-center gap-8 px-12 py-16 text-center text-white lg:min-h-[30vw] lg:w-5/12 lg:text-left'
        }
      >
        <Title level={2} classProps={{ heading: 'text-4xl md:text-5xl font-medium' }}>
          {title}
        </Title>
        <p className="text-base leading-body">{descriptionText}</p>
        <div>
          <Button title={ctaText} link={link || ''} buttonStyle="tertiary" classProps={{ root: 'mt-6' }} />
        </div>
      </div>
    </div>
  );
};
