import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';
import { Carousel } from '@components/molecules/Carousel/Carousel';
import { ListicleItem } from '@components/molecules/ListicleItem/ListicleItem';
import { ListicleListItem } from '@components/molecules/ListicleListItem/ListicleListItem';
import { sortChannelsbyFavorites } from '@utils/common';
import { GetChannelShowtimeListDocument, GetEntitiesDocument } from '@lib/graphql/generated';
import { fetchChannels } from '@lib/graphql/helpers/fetchChannels';
import {
  collectionEntryListicleQuery,
  episodeListicleQuery,
  genreListicleQuery,
  movieListicleQuery,
  personListicleQuery,
  providerListicleQuery,
  showListicleQuery,
} from '@lib/graphql/helpers/fetchListicleData';
import { ssrQuery } from '@lib/graphql/urql';
import { Movie, Show } from '@lib/streaming';

export interface ListiclesProps {
  listicle_type: 'MOVIE' | 'SHOW' | 'CHANNEL' | 'SERIES' | 'EPISODE' | 'PERSON' | 'GENRE' | 'PROVIDER' | 'MANUAL';
  listicle_sorting_type?: 'ranking' | 'numeration';
  listicle_sorting_direction?: 'asc' | 'desc';
  limit?: number;
  genre_filter?: string[];
  items?: string[];
  query: string;
  isArticle?: boolean;
  display?: 'scrollable' | 'list';
  itemData?: [Movie | Show];
  dataSet: [];
  onlyWithAsset?: boolean;
  onlyPrimetime?: boolean;
  showInitially?: boolean;
}

const fetchListicles = async (listicle_type, limit, query, items, onlyWithAsset) => {
  const queries = {
    MOVIE: movieListicleQuery({ limit, query, items, onlyWithAsset }),
    SHOW: showListicleQuery({ limit, query, items, onlyWithAsset }),
    EPISODE: episodeListicleQuery({ limit }),
    GENRE: genreListicleQuery({ limit }),
    PROVIDER: providerListicleQuery({ limit, query, items }),
    PERSON: personListicleQuery({ limit }),
    COLLECTION_ENTRY: collectionEntryListicleQuery({ limit, items, onlyWithAsset }),
    MANUAL: async () => {
      const { data } = await ssrQuery({
        query: GetEntitiesDocument,
        variables: { ids: items, search: null, first: 999, page: 1, onlyWithAsset },
      });
      return data?.entities;
    },
    CHANNEL: async () => {
      const allChannels = await fetchChannels();

      if (query === 'tv' || query === 'settings') {
        const sortedChannelsByFavorites = sortChannelsbyFavorites(allChannels);

        const { data } = await ssrQuery({
          query: GetChannelShowtimeListDocument,
          variables: { ids: sortedChannelsByFavorites?.map((e) => e.id)?.slice(0, limit < 2 ? limit : 2) },
        });
        const showtimeData = data?.channelShowtimeList;

        sortedChannelsByFavorites?.slice(0, limit < 2 ? limit : 2).map((channel) => {
          channel.showtimeData = showtimeData?.find((e) => e.channel.id === channel.id);
          return channel;
        });

        return { data: { data: sortedChannelsByFavorites.slice(0, limit) } };
      } else if (query === 'manual') {
        const channels = [];
        if (items?.length < 1) return;
        items.forEach((e) => {
          const channel = allChannels.data.find((s) => s.id === e);
          if (channel) {
            channels.push(channel);
          }
        });
        return { data: { data: channels } };
      } else {
        const filteredSortedChannels = allChannels.data;
        return { data: { data: filteredSortedChannels.slice(0, limit) } };
      }
    },
  };

  return queries[listicle_type]();
};

export const Listicles = ({
  listicle_type,
  listicle_sorting_type,
  listicle_sorting_direction,
  limit,
  query,
  isArticle,
  display,
  items,
  itemData,
  dataSet,
  onlyWithAsset,
  onlyPrimetime,
  showInitially,
}: ListiclesProps) => {
  const [entries, setEntries] = useState({ data: [] });
  const [lastScroll, setLastScroll] = useState(0);
  const [noFetch, setNoFetch] = useState(true);

  const listicleStyle = {
    default: 'block w-min min-w-72 max-w-full',
  };

  useEffect(() => {
    if (dataSet && dataSet?.length > 0) {
      setEntries({ data: dataSet });
      setNoFetch(true);
    } else if (items && itemData?.length > 0) {
      const entriesData = [];
      items.forEach((item) => {
        const entry = itemData.find((e) => e.id === item);
        if (entry) {
          entriesData.push(entry);
        }
      });
      if (items.length === entriesData.length) {
        setEntries({ data: entriesData });
        setNoFetch(true);
      } else {
        setNoFetch(false);
      }
    } else {
      setNoFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet]);

  function getListicleListNumber(entries, index) {
    if (!entries.data) return null;
    if (listicle_sorting_direction === 'asc') {
      return index + 1;
    } else {
      return entries.data.length - index;
    }
  }

  const foundItems = itemData?.filter((e) => items.includes(e.id));
  const listicleEntries = entries?.data?.length > 0 ? entries : { data: foundItems?.length > 0 ? foundItems : dataSet };

  return (
    <InView
      onChange={(inView) => {
        if (inView && !noFetch && dataSet.length < 1) {
          fetchListicles(listicle_type, limit, query, items, onlyWithAsset).then((res) => {
            setEntries(res);
          });
        }
      }}
    >
      {isArticle ? (
        <>
          {display === 'list' ? (
            <div className="content-auto space-y-16">
              {listicleEntries?.data?.length > 0 &&
                listicleEntries.data.map((entry, index) => (
                  <div key={entry.id + '' + index} className="relative w-full">
                    <div className="space-y-16">
                      <ListicleListItem
                        entry={entry}
                        type={entry?.type || entry.__typename || listicle_type}
                        sorting_type={listicle_sorting_type}
                        style={listicleEntries?.data?.length > 1 ? 'compact' : 'default'}
                        place={getListicleListNumber(listicleEntries, index)}
                      />
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="content-auto content-element-padding pb-36">
              <div className="tvm-scrollbar -my-36 w-full overflow-x-auto py-36 pb-24">
                <div className="flex gap-4">
                  {listicleEntries?.data?.length > 0 &&
                    listicleEntries.data.map((entry, index) => (
                      <ListicleItem
                        key={entry.id || index}
                        entry={entry}
                        listicleType={entry.type || entry.__typename || listicle_type}
                        classProps={{ root: 'flex-none min-w-[100px]' }}
                        query={query}
                        showInitially={showInitially && index < 5}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Carousel
          onScrollComplete={() => {
            setLastScroll((prev) => prev + 1);
          }}
          classProps={{
            carousel: twMerge(listicle_type === 'PERSON' && 'grid grid-flow-col grid-rows-2 justify-start'),
            slide: '!h-auto',
          }}
        >
          {listicleEntries.data.map((entry, index) => (
            <ListicleItem
              lastScroll={lastScroll}
              key={entry.id}
              entry={entry}
              query={query}
              listicleType={
                entry.type || (listicle_type === 'MANUAL' ? entry.__typename : listicle_type) || entry.__typename
              }
              classProps={{ root: listicleStyle.default }}
              primetime={onlyPrimetime}
              showInitially={showInitially && index < 5}
            />
          ))}
        </Carousel>
      )}
    </InView>
  );
};
