import { sortedChannels } from '@utils/sortedChannels';
import { GetChannelsDocument } from '../generated';
import { ssrOptionalAuthQuery, ssrQuery } from '../urql';

export async function fetchChannels(token: string = null) {
  const channelsQuery = token ? ssrOptionalAuthQuery : ssrQuery;

  const { data } = await channelsQuery({
    query: GetChannelsDocument,
    variables: {},
    authToken: token,
  });

  const channelList = (data.channels?.data ?? []).map((channel) => {
    const channelStatic = sortedChannels.filter((e) => e.id === channel.id);
    return {
      ...channel,
      localLogo: channelStatic.length === 1 ? channelStatic[0].localLogo : null,
    };
  });

  return { data: channelList };
}
